<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
            <v-col cols="12">
                <v-stepper v-model="step">
                    <v-stepper-header>
                        <v-stepper-step
                            :complete="step > 1"
                            step="1"
                            @click="step = 1"
                            style="cursor:pointer"
                        >
                            Create New
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="step > 2"
                            step="2"
                            @click="step = 2"
                            style="cursor:pointer"
                        >
                            Maintenance
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            :complete="step > 3"
                            step="3"
                            @click="step = 3"
                            style="cursor:pointer"
                        >
                            Authorize Work
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step step="4" @click="step = 4" style="cursor:pointer">
                            Request History
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-container fluid class="mb-12">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-autocomplete
                                            v-model="mill"
                                            :items="mills"
                                            default=""
                                            item-value="mill_id"
                                            item-text="mill_name"
                                            label="MILL"
                                            clearable
                                            class="border-12"
                                            hide-details=""
                                            solo
                                            dense
                                            @change="(event) => getDivision(event)"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-autocomplete
                                            v-model="division"
                                            :items="divisions"
                                            default=""
                                            item-value="class"
                                            item-text="descr"
                                            label="Division"
                                            clearable
                                            class="border-12"
                                            hide-details=""
                                            solo
                                            dense
                                            @change="(event) => getMachineType(event)"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-autocomplete
                                            v-model="machine_type"
                                            :items="machine_types"
                                            item-value="mach_type"
                                            :item-text="item => item.mach_type +' - '+ item.descr"
                                            label="Machine Type"
                                            clearable
                                            class="border-12"
                                            hide-details=""
                                            solo
                                            dense
                                            @change="(event) => getMachineName(event)"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-autocomplete
                                            v-model="machine"
                                            :items="machines"
                                            item-value="mach_id"
                                            :item-text="item => item.descr"
                                            label="Machine Name"
                                            clearable
                                            class="border-12"
                                            hide-details=""
                                            solo
                                            dense
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" class="text-left">
                                        <v-btn class="mx-2" fab dark color="indigo" @click="createNew()" :disabled="createDisabled">
                                        <v-icon dark> mdi-plus </v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" class="text-right">
                                        <v-btn
                                            class="mr-2"
                                            color="error"
                                            elevation="2"
                                            large
                                            @click="search()"
                                        >
                                        <v-icon class="mr-1">mdi-magnify</v-icon>
                                            Search
                                        </v-btn>
                                    </v-col>
                                                                                                                                
                                </v-row>
                                <v-divider></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <v-card>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-card-title>Draft Create
                                                            <v-spacer></v-spacer>
                                                            <v-text-field
                                                                v-model="$store.state.text"
                                                                append-icon="mdi-magnify"
                                                                label="Search No WR"
                                                                single-line
                                                                @keyup.enter="search"
                                                                hide-details
                                                                solo
                                                                dense
                                                                class="border-12"
                                                                style="max-width: 350px;"
                                                            ></v-text-field>
                                                        </v-card-title>
                                                        <v-data-table
                                                            :headers="headers"
                                                            :items="unschedules"
                                                            :options.sync="option_items"
                                                            :loading="loading"
                                                            loading-text="Please wait, retrieving data"
                                                            :server-items-length="totalItem"
                                                            :page.sync="pagination"
                                                            page-count="10"
                                                            item-key="key"
                                                            class="elevation-1"
                                                            height="400"
                                                            fixed-header
                                                            dense
                                                        >
                                                            <template v-slot:[`item.actions`]="{ item }" >
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                            small
                                                                            class="mr-2"
                                                                            @click="editItem(item)"
                                                                            v-on="on"
                                                                            :disabled="createDisabled"
                                                                        >
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                                <v-tooltip top v-if="item.status == '1' || item.status == 1">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                            small
                                                                            class="mr-2"
                                                                            v-on="on"
                                                                            @click="deleteItem(item)"
                                                                        >
                                                                        mdi-delete
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Delete</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>
                                    <v-dialog v-model="dialog" max-width="1000px" transition="dialog-bottom-transition">                
                                        <v-card>
                                            <v-card-title>{{text_dialog}}</v-card-title>
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                v-model="mill_id"
                                                                :items="mill_ids"
                                                                item-value="mill_id"
                                                                item-text="mill_name"
                                                                label="MILL ID"
                                                                @change="(event) => getDivision(event)"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                v-model="division_id"
                                                                :items="division_ids"
                                                                default=""
                                                                item-value="class"
                                                                item-text="descr"
                                                                label="Division"
                                                                clearable
                                                                @change="(event) => getMachineTypeDialog(event)"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                v-model="mach_type"
                                                                :items="mach_types"
                                                                item-value="mach_type"
                                                                item-text="descr"
                                                                label="MACHINE TYPE"
                                                                @change="(event) => getMachineNameDialog(event)"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                v-model="mach_id"
                                                                :items="mach_ids"
                                                                item-value="mach_id"
                                                                item-text="descr"
                                                                label="MACHINE ID"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                v-model="requested_by"
                                                                :items="employees"
                                                                item-value="nik"
                                                                :item-text="item => item.nik +' - '+ item.nama"
                                                                label="REQUEST By"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-datetime-picker 
                                                                v-model="request_date" 
                                                                label="REQUEST DATE"
                                                                :date-picker-props="dateProps"
                                                                time-format="HH:mm:ss"
                                                            ></v-datetime-picker>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                v-model="status"
                                                                :items="statuses"
                                                                item-value="id"
                                                                :item-text="item => item.id +' - '+ item.label"
                                                                label="STATUS"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="12">
                                                            <v-text-field
                                                                v-model="description"
                                                                label="Description"
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" @click="save()">Save</v-btn>
                                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog
                                        v-model="dialog_save"
                                        hide-overlay
                                        persistent
                                        width="300"
                                        >
                                        <v-card
                                            color="primary"
                                            dark
                                        >
                                            <v-card-text>
                                            Please stand by
                                            <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0"
                                            ></v-progress-linear>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog
                                        v-model="dialogDelete"
                                        max-width="400px"
                                        persistent
                                    >
                                        <v-card style="overflow: hidden;">
                                            <v-card-title class="p-1">                        
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    class="mx-2"
                                                    fab
                                                    x-small
                                                    color="#e83e8c;"
                                                    @click="dialogDelete = false"
                                                >
                                                    <v-icon dark>
                                                        mdi-close
                                                    </v-icon>
                                                </v-btn>
                                            </v-card-title>
                                            <v-card-text class="mb-0">
                                                <v-container>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <h6 class="text-center" style="font-weight:600">
                                                                {{ detailItem ? detailItem.no_wr : '' }}
                                                            </h6>
                                                            <p class="text-center">
                                                                Are you sure delete this Item ?
                                                            </p>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions class="text-center p-3 mt-0">
                                                <v-row justify="center">
                                                    <v-col cols="6" xs="6" sm="4" md="4">
                                                        <v-btn block class="rounded-l p-3" @click="dialogDelete = false">Cancel</v-btn>
                                                    </v-col>
                                                    <v-col cols="6" xs="6" sm="4" md="4">
                                                        <v-btn block class="rounded-l text-white p-3" color="primary" @click="deleteMaint()">Delete</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-row>
                                <v-row class="mt-3 mb-5">
                                    <v-col cols="12" class="text-right">
                                        <v-btn
                                            color="primary"
                                            @click="step = 2"
                                        >
                                        Continue
                                        <v-icon>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                                    <v-layout align-center pr-4>
                                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                                    <v-layout column>
                                        <div>
                                        <strong>{{ snackbar.title }}</strong>
                                        </div>
                                        <div>{{ snackbar.text }}</div>
                                    </v-layout>
                                    </v-layout>
                                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                                    <v-icon>clear</v-icon>
                                    </v-btn>
                                </v-snackbar>
                            </v-container>                            
                        </v-stepper-content>

                        <v-stepper-content step="2">
                            <unscheduled-maintenance-maintenance />

                            <v-container fluid class="mb-12">
                                <v-row>
                                    <v-col cols="12" class="text-right">
                                        <v-btn color="red" @click="step = 1" class="mr-2 white--text">
                                            <v-icon>mdi-chevron-left</v-icon>
                                            Back
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="step = 3"
                                        >
                                        Continue
                                        <v-icon>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>                            
                        </v-stepper-content>

                        <v-stepper-content step="3">
                            <unscheduled-maintenance-authorize />

                            <v-container fluid class="mb-12">
                                <v-row>
                                    <v-col cols="12" class="text-right">
                                        <v-btn color="red" @click="step = 2" class="mr-2 white--text">
                                            <v-icon>mdi-chevron-left</v-icon>
                                            Back
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="step = 4"
                                        >
                                        Continue
                                        <v-icon>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>   
                        </v-stepper-content>

                        <v-stepper-content step="4">
                            <unscheduled-maintenance-history />

                            <v-container fluid class="mb-12">
                                <v-row>
                                    <v-col cols="12" class="text-right">
                                        <v-btn color="red" @click="step = 3" class="mr-2 white--text">
                                            <v-icon>mdi-chevron-left</v-icon>
                                            Back
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container> 
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import UnscheduledMaintenanceMaintenance from './UnscheduledMaintenanceMaintenance.vue'
  import UnscheduledMaintenanceAuthorize from './UnscheduledMaintenanceAuthorize.vue'
  import UnscheduledMaintenanceHistory from './UnscheduledMaintenanceHistory.vue'

  export default {
    components:{
        UnscheduledMaintenanceMaintenance, UnscheduledMaintenanceAuthorize, UnscheduledMaintenanceHistory
    },
    data() {
        return {
            // tzoffsetRestore : new Date().getTimezoneOffset() * 102858, //offset in milliseconds
            tzoffsetConvert : new Date().getTimezoneOffset() * 60000, //offset in milliseconds
            step: 1,
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Maintenance',
                disabled: false,
                href: '/admin/kka/maintenance',
                },
                {
                text: 'Unschedule Maintenance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MAINTENANCE'
            },
            createDisabled: false,
            actionDisabled: false,
            inspectDisabled: false,
            authorizeDisabled: false,
            mills: [],
            mill: '',
            divisions:[],
            division:'',
            machine_types: [],
            machine_type: '',
            machines: [],
            machine: '',
            headers: [
                { text: "Actions", value: "actions", sortable: false },
                {
                    text: "No WR",
                    align: "start",
                    value: "no_wr",
                },
                { text: "Description", value: "description" },
                { text: "Mill Id", value: "mill_id" },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Division", value: "class" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Req Date", value: "request_date" },
                { text: "Req By", value: "requested_by" },
            ],
            unschedules: [],
            option_items: {},
            totalItem: 10,
            pagination: 1,
            dialog: false,
            text_dialog: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            mill_id: '',
            mill_ids: [],
            division_id: '',
            division_ids: [],
            mach_id: '',
            mach_ids: [],
            mach_type: '',
            mach_types: [],
            requested_by: '',
            employees: [],
            request_date: new Date(),
            dateProps: {
                headerColor: 'blue'
            },
            status: '',
            statuses: [
                {
                    'id' : 1,
                    'label' : 'Draft'
                },
                {
                    'id' : 2,
                    'label' : 'Maintenance'
                }
            ],
            description: '',
            btnSaveNew: '',
            dialog_save: false,
            detailItem: {},
            dialogDelete: false
        }
    },
    mounted(){
        this.getMill()
        this.getEmployee()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                
                
                for (let i = 0; i < res.data.data.length; i++) {
                    
                    // if (res.data.data[i]['var_id'] === '60_UN_MTC') {
                    //     this.user_approve1 = res.data.data[i]['var_value']
                    //     console.log(res.data.data[i]['var_value'])
                    // }
                    
                    if (res.data.data[i]['var_id'] === '60_UN_MTC_ITEM') {
                        this.detailAccess = res.data.data[i]['var_value']
                        var create = this.detailAccess.substr(0,1);
                        var action = this.detailAccess.substr(1,1);
                        var inspect = this.detailAccess.substr(2,1);
                        var authorize = this.detailAccess.substr(3,1);
                        this.createDisabled = (create == "1")? false : true;
                        this.actionDisabled = (action == "1")? false : true;
                        this.inspectDisabled = (inspect == "1")? false : true;
                        this.authorizeDisabled = (authorize == "1")? false : true;
                    }
                }
            })
        },
        close(){
            this.dialog = false
            this.text_dialog = ''
            this.clear()
        },
        getEmployee(){
            var data = {
                'nik' : '',
                'category' : '',
                'nama': '',
                'is_active' : 1,
                'page' : 1,
                'item_per_page' : 100
            }

            this.$store
            .dispatch("getMasterMaintenanceEmployee", data)
            .then(res => {
                this.employees = res.data.data
            })
            .catch((err) => {

            })
        },
        async getMill(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.mills = res.data.data
                this.mill_ids = res.data.data
			})
			.catch(err => {
			})
        },
        async getDivision(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/master_division?mill_id=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                // console.log(res);
                // console.log(res.data.errorCode);
                if(res.data.errorCode == "00"){
                    this.divisions = res.data.data.data
                    this.division_ids = res.data.data.data
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    };
                }
                
			})
			.catch(err => {
			})
        },
        async getMachineType(value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${this.mill ? this.mill : ''}&class=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machine_types = res.data
                // this.mach_types = res.data
			})
			.catch(err => {
			})
        },
        async getMachineName(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${this.mill ? this.mill : ''}&mach_type=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machines = res.data
                // this.mach_ids = res.data
			})
			.catch(err => {
			})
        },
        async getMachineTypeDialog(value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${this.mill_id ? this.mill_id : ''}&class=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                // this.machine_types = res.data
                this.mach_types = res.data
			})
			.catch(err => {
			})
        },
        async getMachineNameDialog(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${this.mill_id ? this.mill_id : ''}&mach_type=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                // this.machines = res.data
                this.mach_ids = res.data
			})
			.catch(err => {
			})
        },
        createNew(){
            this.dialog = true
            this.text_dialog = 'Create'
            this.btnSaveNew = 'New'
            this.clear()
        },
        async search(page = 1, itemsPerPage = 10){
            this.loading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?no_wr=${this.$store.state.text}&status=1&mill_id=${this.mill ? this.mill : ''}&class=${this.division ? this.division : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.unschedules = res.data.data
                this.totalItem = res.data.total
                this.pagination = res.data.current_page
            })
        },
        async editItem(item){
            this.detailItem = item
            this.dialog = true
            this.text_dialog = 'Edit'
            this.clear()
            
            console.log("EDIT ITEM");
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?no_wr=${this.$store.state.text}&status=1&mill_id=${this.mill ? this.mill : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&page=${'1'}&pagination=${'10'}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                if (res.data.data[0].status === "1"){
                    console.log(res.data.data[0]);
                    this.mill_id = res.data.data[0].mill_id
                    this.getDivision(this.mill_id)
                    this.division_id = res.data.data[0].class
                    this.getMachineTypeDialog(this.division_id)
                    this.mach_type = res.data.data[0].mach_type
                    this.getMachineNameDialog(this.mach_type)
                    this.mach_id = res.data.data[0].mach_id
                    this.requested_by = res.data.data[0].requested_by
                    if (res.data.data[0].status === "1") {
                        this.status = 1
                    } else{
                        this.status = 2
                    }
                    this.btnSaveNew = 'Edit'
                    this.description = res.data.data[0].description
                    
                    // var localReqDate = new Date((new Date(res.data.data[0].request_date) - this.tzoffsetRestore))
                    
                    this.request_date = new Date(res.data.data[0].request_date)
                } else{
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: "Status WO sudah naik ke team maintenance",
                        visible: true
                    };
                    this.dialog = false
                }
            }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
        },
        deleteItem(item){
            this.detailItem = item
            this.dialogDelete = true
        },
        clear(){
            this.mill_id = ''
            this.division_id = ''
            this.mach_type = ''
            this.mach_id = ''
            this.requested_by = ''
            this.request_date = new Date()
            this.status = ''
            this.description = ''
        },
        async save(){
            this.dialog_save = true

            if (this.mill_id === '' || this.mach_type === '' || this.mach_id === '' || this.requested_by === '' || this.request_date === '' || this.status === '' || this.description === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill data",
                    visible: true
                };

                this.dialog_save = false

                return false;
            }

           
            var localRequestISOTime = new Date(this.request_date - this.tzoffsetConvert)
                .toISOString()
                .slice(0, -1);
                console.log(localRequestISOTime)

            if (this.btnSaveNew === 'New') {
                await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance`, {
                    'mill_id': this.mill_id,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'description': this.description,
                    'requested_by': this.requested_by,
                    'request_date': localRequestISOTime,
                    'status': this.status
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    // this.loading = false
                    this.dialog_save = false
                    this.dialog = false

                    this.search(1,10)
                    this.clear()
                }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } else {
                await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_update_all`, {
                    'no_wr' : this.detailItem.no_wr,
                    'mill_id': this.mill_id,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'description': this.description,
                    'requested_by': this.requested_by,
                    'request_date': localRequestISOTime,
                    'status': this.status
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    this.dialog_save = false
                    this.dialog = false

                    this.search(1,10)
                    this.clear()
                }).catch(err => {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            }
        },
        async deleteMaint(){
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance/delete`, {
                'no_wr': this.detailItem ? this.detailItem.no_wr : '',
                'id': this.detailItem ? this.detailItem.id : ''
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialogDelete = false
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Successfully delete Item',
                    visible: true
                };
                this.search(1,10)
                this.clear()
            }).catch(err => {
                // this.loading = false
                this.dialogDelete = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        }
    },
    watch: {
        option_items: {
            handler () {
                const { page, itemsPerPage } = this.option_items
                this.search(page, itemsPerPage)
            },
            deep: true,
        }
    }
  }
</script>