<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="mill"
                    :items="mills"
                    default=""
                    item-value="mill_id"
                    item-text="mill_name"
                    label="MILL"
                    clearable
                    @change="(event) => getDivision(event)"
                    hide-details=""
                    solo
                    class="border-12"
                    dense                    
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="division"
                    :items="divisions"
                    default=""
                    item-value="class"
                    item-text="descr"
                    label="Division"
                    clearable
                    @change="(event) => getMachineType(event)"
                    hide-details=""
                    solo
                    class="border-12"
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="machine_type"
                    :items="machine_types"
                    item-value="mach_type"
                    :item-text="item => item.mach_type +' - '+ item.descr"
                    label="Machine Type"
                    clearable
                    @change="(event) => getMachineName(event)"
                    hide-details=""
                    solo
                    class="border-12"
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="machine"
                    :items="machines"
                    item-value="mach_id"
                    :item-text="item => item.descr"
                    label="Machine Name"
                    clearable
                    hide-details=""
                    solo
                    class="border-12"
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="text-right">
                <v-btn
                    class="mr-2"
                    color="error"
                    elevation="2"
                    large
                    @click="search()"
                >
                <v-icon class="mr-1">mdi-magnify</v-icon>
                    Search
                </v-btn>
            </v-col>                                                                          
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Maintenance
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search No WR"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                        solo
                                        dense
                                        class="border-12"
                                        style="max-width: 350px;"
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="unschedules"
                                    :options.sync="option_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItem"
                                    :page.sync="pagination"
                                    page-count="10"
                                    item-key="key"
                                    class="elevation-1"
                                    height="400"
                                    fixed-header
                                    dense
                                >
                                    <template v-slot:[`item.start_date`]="{ item }">
                                        <div v-if="item.start_date != '1900-01-01 00:00:00.000'">
                                            {{item.start_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.finish_date`]="{ item }">
                                        <div v-if="item.finish_date != '1900-01-01 00:00:00.000'">
                                            {{item.finish_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                                v-on="on"
                                                
                                                >
                                                mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                small
                                                class="mr-2"
                                                @click="confirmation(item)"
                                                v-on="on"
                                                >
                                                mdi-checkbox-marked-circle-outline
                                                </v-icon>
                                            </template>
                                            <span>Confirmation</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1200px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>Detail Maintenance</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card-title>
                                            Detail Maintenance
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_details"
                                            :items="unschedule_details"
                                            :options.sync="option_details"
                                            :loading="loading_detail"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItemDetail"
                                            :page.sync="paginationDetail"
                                            page-count="10"
                                            item-key="key"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip v-if="item.require_sparepart === '1'" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                        small
                                                        class="mr-2"
                                                        @click="showSparePart(item)"
                                                        v-on="on"
                                                        >
                                                        mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span>Show Req Sparepart</span>
                                                </v-tooltip>
                                            </template>                                            
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogSparePart" max-width="800px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>Request Sparepart</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-5">
                                    <v-col cols="12">
                                        <v-card-title>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="request_sparepart"
                                                append-icon="mdi-magnify"
                                                label="Search No Article"
                                                single-line
                                                @keyup.enter="search_sparepart"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_spareparts"
                                            :items="spareparts"
                                            :options.sync="option_spareparts"
                                            :loading="loading_sparepart"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItemSparepart"
                                            :page.sync="paginationSparePart"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.req_qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.req_qty) }}
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeSparePart">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogConfirmation"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Apakah anda yakin untuk menutup WO ini?
                        </v-card-title>
                        <v-card-text>Bila yakin silahkan tekan Setuju.</v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            text
                            @click="dialogConfirmation = false"
                        >
                            Tutup

                        </v-btn>
                        <v-btn
                            color="green darken-1"
                            text
                            @click="updateOptional()"
                        >
                            Setuju
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

  export default {
    data() {
        return {
            tab: '',
            loading: false,
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MAINTENANCE'
            },
            createDisabled: false,
            actionDisabled: false,
            inspectDisabled: false,
            authorizeDisabled: false,
            mills: [],
            mill: '',
            divisions:[],
            division:'',
            machine_types: [],
            machine_type: '',
            machines: [],
            machine: '',
            headers: [
                {
                    text: "No WR",
                    align: "start",
                    value: "no_wr",
                },
                { text: "Description", value: "description" },
                { text: "Mill Id", value: "mill_id" },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Division", value: "class" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Req Date", value: "request_date" },
                { text: "Req By", value: "requested_by" },
                { text: "Category Maintenance", value: "category_description" },
                { text: "Req Sparepart", value: "require_sparepart" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Total Duration", value: "total_duration" },
                { text: "Total Lost Time", value: "total_lost_time" },
                { text: "Total Down Time", value: "total_down_time" },
                { text: "Total Item", value: "total_item" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            unschedules: [],
            option_items: {},
            totalItem: 10,
            pagination: 1,
            header_details:[
                {
                    text: "No",
                    align: "start",
                    value: "wr_action_no",
                },
                {
                    text: "No WR",
                    value: "no_wr",
                },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Action Type", value: "action_type" },
                { text: "Status Action", value: "status_action" },
                { text: "Shift", value: "shift" },
                { text: "Req Sparepart", value: "require_sparepart" },
                { text: "Observation", value: "observation" },
                { text: "Correction", value: "correction" },
                { text: "Remark Action", value: "remark_action" },
                { text: "Remark Inspection", value: "remark_inspection" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Inspect Date", value: "inspect_date" },
                { text: "Duration", value: "duration" },
                { text: "Lost Time", value: "loss_time" },
                { text: "Down Time", value: "downtime" },
                { text: "Action By", value: "action_by" },
                { text: "Inspected by", value: "inspected_by" },
                { text: "Actions", value: "actions", sortable: false }
            ],
            unschedule_details: [],
            option_details: {},
            loading_detail: false,
            totalItemDetail: 10,
            paginationDetail: 1,
            dialog: false,
            text_dialog: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            mill_id: '',
            mill_ids: [],
            mach_id: '',
            mach_ids: [],
            mach_type: '',
            mach_types: [],
            requested_by: '',
            employees: [],
            request_date: '',
            dateProps: {
                headerColor: 'blue'
            },
            status: '',
            statuses: [
                {
                    'id' : 1,
                    'label' : 'Draft'
                },
                {
                    'id' : 2,
                    'label' : 'Maintenance'
                }
            ],
            description: '',
            btnSaveNew: '',
            dialog_save: false,
            detailMaintenance: {},
            display: 'none',
            dialogSparePart: false,
            request_sparepart: '',
            header_spareparts: [
                {
                    text: "Article Id",
                    align: "start",
                    value: "article_id",
                },
                { text: "Nama Produk", value: "name_product" },
                { text: "Req Qty", value: "req_qty" },
                { text: "Note", value: "note" }
            ],
            spareparts: [],
            option_spareparts: {},
            loading_sparepart: false,
            totalItemSparepart: 10,
            paginationSparePart: 1,
            detailSparePart: {},
            dialogConfirmation: false
        }
    },
    mounted(){
        this.getMill()
        this.getEnvConf()
        // this.getEmployee()
    },
    methods:{
        getEnvConf(){
            console.log("# GET ENV CONF");
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                
                
                for (let i = 0; i < res.data.data.length; i++) {
                    
                    // if (res.data.data[i]['var_id'] === '60_UN_MTC') {
                    //     this.user_approve1 = res.data.data[i]['var_value']
                    //     console.log(res.data.data[i]['var_value'])
                    // }
                    
                    if (res.data.data[i]['var_id'] === '60_UN_MTC_ITEM') {
                        this.detailAccess = res.data.data[i]['var_value']
                        var create = this.detailAccess.substr(0,1);
                        var action = this.detailAccess.substr(1,1);
                        var inspect = this.detailAccess.substr(2,1);
                        var authorize = this.detailAccess.substr(3,1);
                        this.createDisabled = (create == "1")? false : true;
                        this.actionDisabled = (action == "1")? false : true;
                        this.inspectDisabled = (inspect == "1")? false : true;
                        this.authorizeDisabled = (authorize == "1")? false : true;
                    }
                }
            })
        },
        closeSparePart(){
            this.dialogSparePart = false
        },
        search_sparepart(){

        },
        close(){
            this.dialog = false
            this.text_dialog = ''
            this.clear()
            // this.search(1, 10)
        },
        getEmployee(){
            var data = {
                'nik' : '',
                'category' : '',
                'nama': '',
                'is_active' : 1,
                'page' : 1,
                'item_per_page' : 100
            }

            this.$store
            .dispatch("getMasterMaintenanceEmployee", data)
            .then(res => {
                this.employees = res.data.data
            })
            .catch((err) => {

            })
        },
        async getMill(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.mills = res.data.data
                this.mill_ids = res.data.data
			})
			.catch(err => {
			})
        },
        async getDivision(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/master_division?mill_id=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                console.log(res);
                console.log(res.data.errorCode);
                if(res.data.errorCode == "00"){
                    this.divisions = res.data.data.data
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    };
                }
                
			})
			.catch(err => {
			})
        },
        async getMachineType(value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${this.mill ? this.mill : ''}&class=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machine_types = res.data
                this.mach_types = res.data
			})
			.catch(err => {
			})
        },
        async getMachineName(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${this.mill ? this.mill : ''}&mach_type=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machines = res.data
                this.mach_ids = res.data
			})
			.catch(err => {
			})
        },
        createNew(){
            this.dialog = true
            this.text_dialog = 'Create'
            this.btnSaveNew = 'New'
            this.clear()
        },
        async search(page = 1, itemsPerPage = 10){
            this.loading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?no_wr=${this.$store.state.text}&status=3&mill_id=${this.mill ? this.mill : ''}&class=${this.division ? this.division : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                console.log(res.data.data);
                this.loading = false
                this.unschedules = res.data.data
                this.totalItem = res.data.total
                this.pagination = res.data.current_page
            })
        },
        async detail(page = 1, itemsPerPage = 10){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance?mill_id=${this.detailMaintenance.mill_id}&mach_type=${this.detailMaintenance.mach_type}&mach_id=${this.detailMaintenance.mach_id}&no_wr=${this.detailMaintenance.no_wr}&status_action=3&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_detail = false
                this.unschedule_details = res.data.data
                this.totalItemDetail = res.data.total
                this.paginationDetail = res.data.current_page
            })
        },
        editItem(item){
            this.display = 'none'
            this.detailMaintenance = item
            // this.$store.dispatch('getDetailMaintainanceAction', { item })
            this.dialog = true
            this.text_dialog = 'Edit'
            this.clear()
            this.unschedule_details = []
            this.loading_detail = true
            this.detail(1, 10)
        },
        clear(){
            this.mill_id = ''
            this.mach_type = ''
            this.mach_id = ''
            this.requested_by = ''
            this.request_date = ''
            this.status = ''
            this.description = ''
        },
        async save(){
            this.dialog_save = true

            if (this.mill_id === '' || this.mach_type === '' || this.mach_id === '' || this.requested_by === '' || this.request_date === '' || this.status === '' || this.description === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill data",
                    visible: true
                };

                this.dialog_save = false

                return false;
            }

            if (this.btnSaveNew === 'New') {
                await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance`, {
                    'mill_id': this.mill_id,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'description': this.description,
                    'requested_by': this.requested_by,
                    'request_date': this.request_date,
                    'status': this.status
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    // this.loading = false
                    this.dialog_save = false
                    this.dialog = false

                    this.search(1,10)
                    this.clear()
                }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } else {
                
            }
        },
        showSparePart(item){
            this.detailSparePart = item
            this.dialogSparePart = true
            this.spareparts = []
            this.loading_sparepart = true
            this.getSparePart(1, 10)
        },
        async getSparePart(page = 1, itemsPerPage = 10){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart?mill_id=${this.detailSparePart.mill_id}&no_wr=${this.detailSparePart.no_wr}&wr_action_no=${this.detailSparePart.wr_action_no}&page=${page > 1 ? page : ""}&pagination=${itemsPerPage}`,
            {
                headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`},
            }
            )
            .then((res) => {
                this.loading_sparepart = false

                this.spareparts = res.data.data
                this.totalItemSparepart = res.data.total
                this.paginationSparePart = res.data.current_page

            });
        },
        confirmation(item){
            this.dialogConfirmation = true
            this.detailMaintenance = item
        },
        async updateOptional(){
            console.log(this.$store.state.user.username);
            await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance/updateStatusOptional/${this.detailMaintenance.no_wr}`, {
                'mill_id': this.detailMaintenance.mill_id,
                'mach_type': this.detailMaintenance.mach_type,
                'mach_id': this.detailMaintenance.mach_id,
                'no_wr': this.detailMaintenance.no_wr,
                // 'close_date' : new Date().toISOString,
                'close_by' : this.$store.state.user.username,
                'status': 4
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialogConfirmation = false
                this.search(1,10)

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Update Status",
                    visible: true
                };
            }).catch(err => {

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
    },
    watch: {
        option_items: {
            handler () {
                const { page, itemsPerPage } = this.option_items
                this.search(page, itemsPerPage)
            },
            deep: true,
        },
        option_details:{
            handler () {
                const { page, itemsPerPage } = this.option_details
                if (this.detailMaintenance) {
                    this.detail(page, itemsPerPage)
                }
            },
            deep: true,
        },
        option_spareparts:{
            handler () {
                const { page, itemsPerPage } = this.option_spareparts
                if (this.detailSparePart) {
                    this.getSparePart(page, itemsPerPage)
                }
            },
            deep: true,
        }
        // detailMaintenance
    }
  }
</script>