<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="3" style="padding-top: 0px">
                <v-combobox
                    v-model="actionType"
                    :items="actionTypes"
                    label="Action Type"
                    clearable
                >
                </v-combobox>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3" style="padding-top: 0px">
                <v-autocomplete
                    v-model="employee"
                    :items="employees"
                    item-value="nik"
                    :item-text="item => item.nik +' - '+ item.nama"
                    label="Action By"
                ></v-autocomplete>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" style="padding-top: 0px">
                <v-dialog
                    ref="startDateFromDialog"
                    v-model="startDateFromModal"
                    :return-value.sync="filterStartDateFrom"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterStartDateFrom"
                            label="Start Date From"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filterStartDateFrom"
                        scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="startDateFromModal = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateFromDialog.save(filterStartDateFrom)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="3" style="padding-top: 0px">
                <v-dialog
                    ref="startDateToDialog"
                    v-model="startDateToModal"
                    :return-value.sync="filterStartDateTo"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterStartDateTo"
                            label="Start Date To"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filterStartDateTo"
                        scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="startDateToModal = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.startDateToDialog.save(filterStartDateTo)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" style="padding-top: 0px">
                <v-dialog
                    ref="finishDateFromDialog"
                    v-model="finishDateFromModal"
                    :return-value.sync="filterFinishDateFrom"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFinishDateFrom"
                            label="Finish Date From"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filterFinishDateFrom"
                        scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="finishDateFromModal = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.finishDateFromDialog.save(filterFinishDateFrom)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" style="padding-top: 0px">
                <v-dialog
                    ref="finishDateTodialog"
                    v-model="finishDateToModal"
                    :return-value.sync="filterFinishDateTo"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="filterFinishDateTo"
                            label="Finish Date To"
                            prepend-icon="mdi-calendar"
                            readonly
                            clearable
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filterFinishDateTo"
                        scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="finishDateToModal = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.finishDateTodialog.save(filterFinishDateTo)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>            

        </v-row>

        <v-row class = "mt-5">
            <v-col cols="6" class="text-left">
                <v-btn class="mx-2" fab dark color="indigo" @click="createNew()" :disabled="actionDisabled">
                <v-icon dark> mdi-plus </v-icon>
                </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
                <v-btn
                    class="mr-2"
                    color="success"
                    elevation="2"
                    large
                    rounded
                    @click="search()"
                >
                    <v-icon dark> mdi-magnify </v-icon> Refresh
                </v-btn>
            </v-col>
        </v-row>

        <v-row v-show="this.detailItem.no_wr === this.item.no_wr ? true: false">
            <v-col cols="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Maintenance Action
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="this.item.no_wr"
                                        append-icon="mdi-magnify"
                                        label="Search No WR"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="unschedules"
                                    :options.sync="option_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItem"
                                    :page.sync="pagination"
                                    page-count="10"
                                    item-key="key"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                                v-on="on"
                                                :disabled="actionDisabled"
                                                >
                                                mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-dialog v-model="dialog" max-width="1000px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>{{text_dialog}}</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="type"
                                            :items="actionTypes"
                                            label="Action Type"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-autocomplete
                                            v-model="employ"
                                            :items="employees"
                                            item-value="nik"
                                            :item-text="item => item.nik +' - '+ item.nama"
                                            label="Action By"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="12" md="3" >
                                        <v-datetime-picker
                                            v-model="start_date"
                                            label="Start Date"
                                            :date-picker-props="dateProps"
                                            time-format="HH:mm:ss"
                                        ></v-datetime-picker>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3" >
                                        <v-datetime-picker
                                            v-model="finish_date"
                                            label="Finish Date"
                                            :date-picker-props="dateProps"
                                            time-format="HH:mm:ss"
                                        ></v-datetime-picker>
                                    </v-col>

                                    <!-- <v-col cols="12" xs="12" sm="12" md="3" >
                                        <v-dialog
                                            ref="startDateToDialog"
                                            v-model="start_date_modal"
                                            :return-value.sync="start_date"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="start_date"
                                                    label="Start Date"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="start_date"
                                                scrollable
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="start_date_modal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.startDateToDialog.save(start_date)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-dialog
                                            ref="finishDateToDialog"
                                            v-model="finish_date_modal"
                                            :return-value.sync="finish_date"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="finish_date"
                                                    label="Finish Date"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    clearable
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="finish_date"
                                                scrollable
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="finish_date_modal = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.finishDateToDialog.save(finish_date)"
                                            >
                                                OK
                                            </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col> -->

                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-combobox
                                            v-model="actionShift"
                                            :items="actionShifts"
                                            label="Shift"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="lossTime"
                                            label="Loss Time (in Minutes)"
                                            type="number"
                                            min="0"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-autocomplete
                                            v-model="statusAction"
                                            :items="statuses"
                                            label="Status"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-textarea
                                            v-model="observation"
                                            label="Observation"
                                            rows="3"
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-textarea
                                            v-model="correction"
                                            label="Correction"
                                            rows="3"
                                        >
                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-textarea
                                            v-model="remark"
                                            label="Remark"
                                            rows="3"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="btnSaveNew === 'Edit'" color="green white--text" @click="addRequestSparepart()"><v-icon dark> mdi-plus </v-icon> Request Sparepart</v-btn>
                            <v-btn color="primary" @click="save()">Save</v-btn>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialog_save"
                    hide-overlay
                    persistent
                    width="300"
                    >
                    <v-card
                        color="primary"
                        dark
                    >
                        <v-card-text>
                        Please stand by
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogSparePart" max-width="800px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>Request Sparepart</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row class="mt-5">
                                    <v-col cols="12">
                                        <v-card-title>
                                            <v-btn class="mx-2" fab dark color="indigo" @click="createNewRequestSparepart()">
                                                <v-icon dark> mdi-plus </v-icon>
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                            <v-text-field
                                                v-model="request_sparepart"
                                                append-icon="mdi-magnify"
                                                label="Search No Article"
                                                single-line
                                                @keyup.enter="search_sparepart"
                                                hide-details
                                            ></v-text-field>
                                        </v-card-title>
                                        <v-data-table
                                            :headers="header_spareparts"
                                            :items="spareparts"
                                            :options.sync="option_spareparts"
                                            :loading="loading_sparepart"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItemSparepart"
                                            :page.sync="paginationSparePart"
                                            page-count="10"
                                            class="elevation-1"
                                        >
                                            <template v-slot:[`item.req_qty`]="{ item }">
                                                {{ $store.getters.convertToCurrency(item.req_qty) }}
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                <v-icon
                                                    small
                                                    class="mr-2"
                                                    @click="editItemSparePart(item)"
                                                >
                                                    mdi-pencil
                                                </v-icon>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeSparePart">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogAddRequest"
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title>{{textRequestSparepart}}</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="6">
                                        <v-autocomplete
                                            v-model="article"
                                            :items="articles"
                                            item-value="article_id"
                                            item-text="descr"
                                            label="Article Name"
                                            required
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6">
                                        <v-text-field
                                            v-model="req_qty"
                                            label="Req Qty"
                                            type="number"
                                            required
                                            min="0"
                                            :rules="qtyRules"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <v-textarea
                                            v-model="note"
                                            name="input-7-4"
                                            label="Catatan"
                                            solo
                                            auto-grow
                                            rows="4"
                                            :counter="400"
                                            required
                                            :rules="noteRules"
                                        >
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-btn block class="rounded-xl" color="primary" @click="saveAddRequest()">Save</v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: ['item', 'display'],
    data() {
        return { 
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MAINTENANCE'
            },
            createDisabled: false,
            actionDisabled: false,
            inspectDisabled: false,
            authorizeDisabled: false,
            maintenanceDisabled:false,
            actionTypes: ['Action', 'Trial Mesin'],
            actionType: '',
            employees: [],
            employee: '',
            startDateFromModal:false,
            filterStartDateFrom: '',
            startDateToModal: false,
            filterStartDateTo: '',
            finishDateFromModal: false,
            filterFinishDateFrom: '',
            finishDateToModal: false,
            filterFinishDateTo: '',
            loading: false,
            detailItem: {},
            unschedules: [],
            headers: [
                {
                    text: "No",
                    align: "start",
                    value: "wr_action_no",
                },
                { text: "Actions", value: "actions", sortable: false },
                {
                    text: "No WR",
                    value: "no_wr",
                },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Action Type", value: "action_type" },
                { text: "Status Action", value: "status_action" },
                { text: "Shift", value: "shift" },
                { text: "Req Sparepart", value: "require_sparepart" },
                { text: "Observation", value: "observation" },
                { text: "Correction", value: "correction" },
                { text: "Remark Action", value: "remark_action" },
                { text: "Remark Inspection", value: "remark_inspection" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Duration", value: "duration" },
                { text: "Lost Time", value: "loss_time" },
                { text: "Down Time", value: "downtime" },
                { text: "Action By", value: "action_by" },
                { text: "Inspected by", value: "inspected_by" }
                
            ],
            option_items: {},
            totalItem: 10,
            pagination: 1,
            detailItem_temp: {},
            displayAction: 'block',
            dialog: false,
            text_dialog: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            type: '', 
            employ: '',
            start_date_modal: false,
            start_date: '',
            finish_date_modal: false,
            finish_date: '',
            actionShifts: ['1', '2', '3'],
            actionShift:'',
            lossTime: '',
            statusAction: '',
            statuses: ['Draft', 'Inspect'],
            observation: '',
            correction: '',
            remark: '',
            btnSaveNew: '',
            dialog_save: false,
            requestDate: new Date(),
            detailAction: {},
            dialogSparePart: false,
            request_sparepart: '',
            header_spareparts: [
                {
                    text: "Article Id",
                    align: "start",
                    value: "article_id",
                },
                { text: "Nama Produk", value: "name_product" },
                { text: "Req Qty", value: "req_qty" },
                { text: "Note", value: "note" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            spareparts: [],
            option_spareparts: {},
            loading_sparepart: false,
            totalItemSparepart: 10,
            paginationSparePart: 1,
            dialogAddRequest: false,
            textRequestSparepart: '',
            article: '',
            articles: [],
            req_qty: '',
            qtyRules:[
                v => !!v || 'Qty is required',
            ],
            note: '',
            noteRules:[
                v => !!v || 'Note is required',
                v => v.length <= 400 || 'Max 400 characters'
            ],
            btReqSparepartSave: '',
            detailSparepart: {}
        }
    },
    computed: mapState([
        'detailMaintenance'
    ]),
    mounted(){
        this.getActionBy()
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            // console.log("# GET ENV CONF");
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                
                
                for (let i = 0; i < res.data.data.length; i++) {
                    
                    // if (res.data.data[i]['var_id'] === '60_UN_MTC') {
                    //     this.user_approve1 = res.data.data[i]['var_value']
                    //     console.log(res.data.data[i]['var_value'])
                    // }
                    
                    if (res.data.data[i]['var_id'] === '60_UN_MTC_ITEM') {
                        this.detailAccess = res.data.data[i]['var_value']
                        var create = this.detailAccess.substr(0,1);
                        var action = this.detailAccess.substr(1,1);
                        var inspect = this.detailAccess.substr(2,1);
                        var authorize = this.detailAccess.substr(3,1);
                        this.createDisabled = (create == "1")? false : true;
                        this.actionDisabled = (action == "1")? false : true;
                        this.inspectDisabled = (inspect == "1")? false : true;
                        this.authorizeDisabled = (authorize == "1")? false : true;
                        this.maintenanceDisabled = (action == "1" || inspect == "1")? false : true;
                    }
                }
            })
        },
        search_sparepart(){
            this.getSparePart(1, 10)
        },
        closeSparePart(){
            this.dialogSparePart = false
        },
        close(){
            this.dialog = false
            this.text_dialog = ''
            this.btnSaveNew = ''
        },
        async getActionBy(){
            var isActive = '1'
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/master_employee?is_active=${isActive}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
            .then(res => {
                this.employees = res.data
            })
        },
        createNew(){
            this.clear()
            this.dialog = true
            this.text_dialog = 'Create Maintenance Action'
            this.btnSaveNew = 'New'
        },
        async editItem(item){
            this.clear()
            this.detailAction = item
            this.dialog = true
            this.text_dialog = 'Edit Maintenance Action'
            this.requestDate = new Date(item.request_date)
            this.btnSaveNew = 'Edit'
            console.log("EDIT ITEM ACTION");
            console.log(this.$store.state.detailMaintenance);
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance?mill_id=${item.mill_id}&mach_type=${item.mach_type}&mach_id=${item.mach_id}&no_wr=${item.no_wr}&wr_action_no=${item.wr_action_no}&status_action=1&page=1&pagination=10`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                var data = res.data.data[0];
                if (res.data.data.length > 0){
                    if (data.action_type === 'A') {
                        this.type = 'Action'
                    } else {
                        this.type = 'Trial Mesin'
                    }
                    // var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
                    // console.log(tzoffset);
                    // console.log(data.start_date);
                    // var localStartISOTime = new Date((new Date(data.start_date) - tzoffset))
                    //     .toISOString()
                    //     .slice(0, -1);
                    // console.log(localStartISOTime);
                    // var localFinishISOTime = new Date((new Date(data.finish_date) - tzoffset))
                    //     .toISOString()
                    //     .slice(0, -1);
                    // console.log(localFinishISOTime);
                    this.employ = data.action_by
                    this.start_date = new Date(data.start_date)
                    this.finish_date = new Date(data.finish_date)
                    this.actionShift = data.shift
                    this.lossTime = data.loss_time

                    this.statusAction = data.status_action == "1" ? "Draft" : "Inspect";

                    this.observation = data.observation
                    this.correction = data.correction
                    this.remark = data.remark_action
                } else {
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: "WO sudah naik ke inspect",
                        visible: true
                    };
                }
            })
            .catch(err => {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })

        },
        async search(page = 1, itemsPerPage = 10){
            this.detailItem = this.item

            this.loading = true

            this.displayAction = 'none'

            this.unschedules = []

            var fltActType = '';
            switch (this.actionType) {
                case 'Action':
                    fltActType = 'A';
                    break;
                case 'Trial Mesin':
                    fltActType = 'T';
                    break;
                default:
                    fltActType = '';
                    break;
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance?mill_id=${this.detailItem.mill_id}&mach_type=${this.detailItem.mach_type}&mach_id=${this.detailItem.mach_id}&no_wr=${this.detailItem.no_wr}&status_action=1&action_type=${fltActType}&action_by=${this.employee ? this.employee : ''}&start_date=${this.filterStartDateFrom ? this.filterStartDateFrom : ''}&start_date_end=${this.filterStartDateTo ? this.filterStartDateTo : ''}&finish_date=${this.filterFinishDateFrom ? this.filterFinishDateFrom : ''}&finish_date_end=${this.filterFinishDateTo ? this.filterFinishDateTo : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.unschedules = res.data.data
                this.totalItem = res.data.total
                this.pagination = res.data.current_page
                this.detailItem_temp = this.detailItem
            })
            
        },
        clear(){
            this.type = ''
            this.employ = ''
            this.start_date = ''
            this.finish_date = ''
            this.actionShift = ''
            this.lossTime = ''
            this.statusAction = ''
            this.observation = ''
            this.correction = ''
            this.remark = ''
        },
        async save(){

            this.dialog_save = true

            if (this.type === '' || this.employ === '' || this.start_date === '' || this.finish_date === '' || this.actionShift === '' || this.status === '' || this.lossTime === '' || this.statusAction === '' || this.observation === '' || this.correction === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill data",
                    visible: true
                };

                this.dialog_save = false

                return false;
            }
            console.log("save action");
            console.log(this.$store.state.detailMaintenance);
            console.log(this.detailItem)

            if (this.btnSaveNew === 'New') {
                var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
                var localStartISOTime = new Date(this.start_date - tzoffset)
                    .toISOString()
                    .slice(0, -1);
                var localFinishISOTime = new Date(this.finish_date - tzoffset)
                    .toISOString()
                    .slice(0, -1);
                var actType = this.type == "Action" ? 'A' : 'T';

                var stat = this.statusAction == "Inspect" ? 2 : 1;

                if(localStartISOTime > localFinishISOTime){
                    this.dialog_save = false
                    this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'Tangal selesai harus lebih besar, kalau belum tahu kapan selesai isi saja sembarang',
                            visible: true
                        };
                } else {
                     await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance`, {
                        'mill_id': this.$store.state.detailMaintenance.item.mill_id,
                        'mach_type': this.$store.state.detailMaintenance.item.mach_type,
                        'mach_id': this.$store.state.detailMaintenance.item.mach_id,
                        'no_wr': this.$store.state.detailMaintenance.item.no_wr,
                        'action_type': actType,
                        'status_action': stat,
                        'shift': this.actionShift,
                        'observation': this.observation,
                        'correction': this.correction,
                        'remark_action': this.remark ? this.remark : '',
                        'start_date': localStartISOTime,
                        'finish_date': localFinishISOTime,
                        'loss_time': this.lossTime,
                        'action_by': this.employ,
                        'request_date': ''
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        console.log(res)
                        if(res.data.errorCode == '00') {
                            this.dialog_save = false
                            this.dialog = false
                            this.search(1,10)
                            this.clear()
                            this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Saved",
                                visible: true
                            };
                        } else {
                            this.dialog_save = false
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: res.data.errorMessage,
                                visible: true
                            };
                        }
                    }).catch(err => {
                        this.dialog_save = false
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                    })
                }
            } else {
                if(localStartISOTime > localFinishISOTime){
                    this.dialog_save = false
                    this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: 'Tangal selesai harus lebih besar, kalau belum tahu kapan selesai isi saja sembarang',
                            visible: true
                        };
                } else {
                    this.update()
                }
            }
        },
        async update(){
            var actType = this.type == "Action" ? 'A' : 'T';
            
            var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
            console.log(tzoffset);
            var localStartISOTime = new Date(this.start_date - tzoffset)
                .toISOString()
                .slice(0, -1);
            console.log(localStartISOTime);
            var localFinishISOTime = new Date(this.finish_date - tzoffset)
                .toISOString()
                .slice(0, -1);
            
            var stat = this.statusAction == "Inspect" ? 2 : 1;

            await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance/update`, {
                'no_wr': this.$store.state.detailMaintenance.item.no_wr,
                'wr_action_no': this.detailAction.wr_action_no,
                'action_type': actType,
                'status_action': stat,
                'shift': this.actionShift,
                'observation': this.observation,
                'correction': this.correction,
                'remark_action': this.remark ? this.remark : '',
                'start_date': localStartISOTime,
                'finish_date': localFinishISOTime,
                'loss_time': this.lossTime,
                'action_by': this.employ,
                'require_sparepart' : this.detailAction.require_sparepart
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                if(res.data.errorCode == '00') {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "success",
                        icon: "mdi-checkbox-marked-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Success",
                        text: "Successfully Saved",
                        visible: true
                    };
                    this.dialog = false
                    this.search(1,10)
                    this.clear()
                } else {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    };
                }
            }).catch(err => {
                this.dialog_save = false

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        addRequestSparepart(){
            this.dialogSparePart = true
            this.spareparts = []
            this.getSparePart(1,10)
            this.getArticles()
        },
        async getSparePart(page = 1, itemsPerPage = 10){
            this.loading_sparepart = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart?mill_id=${this.detailAction.mill_id}&no_wr=${this.detailAction.no_wr}&wr_action_no=${this.detailAction.wr_action_no}&page=${page > 1 ? page : ""}&pagination=${itemsPerPage}`,
            {
                headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`},
            }
            )
            .then((res) => {
                this.loading_sparepart = false

                this.spareparts = res.data.data
                this.totalItemSparepart = res.data.total
                this.paginationSparePart = res.data.current_page

            });
        },
        clearSparepart(){
            this.article = ''
            this.req_qty = ''
            this.note = ''
        },
        editItemSparePart(item){
            this.clearSparepart()
            this.detailSparepart = item
            this.dialogAddRequest = true
            this.textRequestSparepart = 'Edit Request Sparepart'
            this.btReqSparepartSave = 'edit'

            this.article = item.article_id
            this.req_qty = this.$store.getters.convertToCurrency(item.req_qty)
            this.note = item.note
        },
        createNewRequestSparepart(){
            this.clearSparepart()
            this.dialogAddRequest = true
            this.textRequestSparepart = 'Add Request Sparepart'
            this.btReqSparepartSave = 'new'
        },
        async saveAddRequest(){
            this.dialog_save = true

            // if (this.article === '' || this.req_qty === '' || this.note === '') {
            if (this.req_qty === '' || this.note === '') {
                this.dialog_save = false
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: 'Lengkapi semua inputan',
                    visible: true
                };
                return false;
            } else {
                // this.detailAction
                if (this.btReqSparepartSave === 'new') {
                    var now = new Date();
                    var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
                    var localISOTime = new Date(now - tzoffset)
                        .toISOString()
                        .slice(0, -1);

                    await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart`, {
                        'no_wr': this.detailAction.no_wr,
                        'mach_type': this.detailAction.mach_type,
                        'mach_id': this.detailAction.mach_id,
                        'wr_action_no': this.detailAction.wr_action_no,
                        'mill_id': this.detailAction.mill_id,
                        'article_id': this.article,
                        'req_qty': this.req_qty,
                        'req_date': localISOTime,
                        'req_type': "G",
                        'note': this.note,
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                    }).then(res => {
                        this.dialog_save = false;
                        this.dialogAddRequest = false
                        this.getSparePart(1, 10)
                        this.clearSparepart()
                        this.search(1, 10)
                        // this.dialog = false

                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Req Sparepart",
                            visible: true
                        };
                    }).catch(err => {
                        this.dialog_save = false;
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                    })
                } else {
                    this.updateSparePart()
                }
            }
            
        },
        async updateSparePart(){
            var now = new Date();
            var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
            var localISOTime = new Date(now - tzoffset)
                .toISOString()
                .slice(0, -1);

            await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart/update`, {
                'no_wr': this.detailSparepart.no_wr,
                'mach_type': this.detailSparepart.mach_type,
                'mach_id': this.detailSparepart.mach_id,
                'wr_action_no': this.detailSparepart.wr_action_no,
                'mill_id': this.detailSparepart.mill_id,
                'item_no' : this.detailSparepart.item_no,
                'article_id': this.article,
                'req_qty': this.req_qty,
                'req_date': localISOTime,
                'req_type': "G",
                'note': this.note,
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialog_save = false;
                this.dialogAddRequest = false
                this.getSparePart(1, 10)
                this.clearSparepart()
                this.search(1, 10)
                this.dialog = false

                this.snackbar = {
                    color: "success",
                    icon: "mdi-checkbox-marked-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Successfully Update Req Sparepart",
                    visible: true
                };
            }).catch(err => {
                this.dialog_save = false;
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        async getArticles() {
            await axios
                .get(
                `${process.env.VUE_APP_URL}/api/master/kka/sparepart/sparepart`,
                {
                    headers: {
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                    },
                }
                )
                .then((res) => {
                    this.articles = res.data;
                });
        },
    },
    watch: {
        option_items: {
            handler () {
                const { page, itemsPerPage } = this.option_items
                this.search(page, itemsPerPage)
            },
            deep: true
        },
        option_spareparts: {
            handler () {
                const { page, itemsPerPage } = this.option_spareparts
                if (this.detailAction) {
                    this.getSparePart(page, itemsPerPage)
                }
            },
            deep: true
        }
    }
}
</script>