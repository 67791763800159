<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="mill"
                    :items="mills"
                    default=""
                    item-value="mill_id"
                    item-text="mill_name"
                    label="MILL"
                    clearable
                    @change="(event) => getDivision(event)"
                    hide-details=""
                    solo
                    dense
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="division"
                    :items="divisions"
                    default=""
                    item-value="class"
                    item-text="descr"
                    label="Division"
                    clearable
                    @change="(event) => getMachineType(event)"
                    hide-details=""
                    solo
                    dense
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="machine_type"
                    :items="machine_types"
                    item-value="mach_type"
                    :item-text="item => item.mach_type +' - '+ item.descr"
                    label="Machine Type"
                    clearable
                    @change="(event) => getMachineName(event)"
                    hide-details=""
                    solo
                    dense
                    class="border-12"
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="3">
                <v-autocomplete
                    v-model="machine"
                    :items="machines"
                    item-value="mach_id"
                    :item-text="item => item.descr"
                    label="Machine Name"
                    clearable
                    hide-details=""
                    solo
                    class="border-12"
                    dense
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" class="text-right">
                <v-btn
                    class="mr-2"
                    color="error"
                    elevation="2"
                    large
                    @click="search()"
                >
                <v-icon class="mr-1">mdi-magnify</v-icon>
                    Search
                </v-btn>
            </v-col>                                                                          
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Maintenance
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        v-model="$store.state.text"
                                        append-icon="mdi-magnify"
                                        label="Search No WR"
                                        single-line
                                        @keyup.enter="search"
                                        hide-details
                                        solo
                                        dense
                                        class="border-12"
                                        style="max-width: 350px;"
                                    ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                    :headers="headers"
                                    :items="unschedules"
                                    :options.sync="option_items"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItem"
                                    :page.sync="pagination"
                                    page-count="10"
                                    item-key="key"
                                    class="elevation-1"
                                    height="400"
                                    fixed-header
                                    dense
                                >
                                    <template v-slot:[`item.start_date`]="{ item }">
                                        <div v-if="item.start_date != '1900-01-01 00:00:00.000'">
                                            {{item.start_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.finish_date`]="{ item }">
                                        <div v-if="item.finish_date != '1900-01-01 00:00:00.000'">
                                            {{item.finish_date}}
                                        </div>
                                    </template>
                                    <template v-slot:[`item.category_description`]="{ item }">
                                        <v-btn
                                            v-if="item.category_description === null"
                                            color="red text-white"
                                            elevation="2"
                                            small
                                            rounded
                                            @click="changeItemCategory(item)"
                                            :disabled="maintenanceDisabled"
                                        >
                                            Category
                                        </v-btn>
                                        <!-- <span v-else>{{item.category_description}}</span> -->
                                        <span v-else>
                                            <v-btn
                                                
                                                color="red text-white"
                                                elevation="2"
                                                small
                                                rounded
                                                @click="changeItemCategory(item)"
                                                :disabled="maintenanceDisabled"
                                            >
                                                {{item.category_description}}
                                            </v-btn>
                                        </span>
                                    </template>

                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon
                                                small
                                                class="mr-2"
                                                @click="editItem(item)"
                                                v-on="on"
                                                :disabled="maintenanceDisabled"
                                                >
                                                mdi-pencil
                                                </v-icon>
                                            </template>
                                            <span>Edit</span>
                                        </v-tooltip>
                                        
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-dialog v-model="dialog" max-width="1200px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <!-- <v-card-title>{{text_dialog}}</v-card-title> -->
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <v-tabs
                                            v-model="tab"
                                            color="#1565c0"
                                        > 
                                            <v-tab href="#tab-2">
                                                ACTION
                                            </v-tab>
                                            <v-tab href="#tab-3">
                                                INSPECTION
                                            </v-tab>
                                            <v-tab href="#tab-4">
                                                HISTORY
                                            </v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                value="tab-2"
                                            >
                                                <unscheduled-maintenance-action :item="this.detailMaintenance" :display="this.display"/>
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                value="tab-3"
                                            >
                                                <unscheduled-maintenance-inspect :item="this.detailMaintenance" :display="this.display"/>
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                value="tab-4"
                                            >
                                                <unscheduled-maintenance-action-history :item="this.detailMaintenance" :display="this.display"/>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog
                    v-model="dialogEditMaintenance"
                    max-width="500px"
                >
                    <v-card>
                        <v-card-title>Edit Category Maintenance</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12">
                                        <v-autocomplete
                                            v-model="category"
                                            :items="maintenanceCategories"
                                            item-value="category_id"
                                            item-text="description"
                                            label="Maintenance Category"
                                        ></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-btn block class="rounded-xl" color="primary" @click="saveCategoryMaintenance()">Save</v-btn>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
  import UnscheduledMaintenanceAction from './UnscheduledMaintenanceAction.vue'
  import UnscheduledMaintenanceInspect from './UnscheduledMaintenanceInspect.vue'
  import UnscheduledMaintenanceActionHistory from './UnscheduledMaintenanceActionHistory.vue'

  export default {
    components:{
        UnscheduledMaintenanceAction, UnscheduledMaintenanceInspect, UnscheduledMaintenanceActionHistory
    },
    data() {
        return {
            tab: '',
            loading: false,
            data_var: {
                entity_id : 'KKA',
                appl_id : 'MAINTENANCE'
            },
            createDisabled: false,
            actionDisabled: false,
            inspectDisabled: false,
            authorizeDisabled: false,
            maintenanceDisabled:false,
            mills: [],
            mill: '',
            divisions:[],
            division:'',
            machine_types: [],
            machine_type: '',
            machines: [],
            machine: '',
            headers: [
                { text: "Actions", value: "actions", sortable: false },
                {
                    text: "No WR",
                    align: "start",
                    value: "no_wr",
                },
                { text: "Description", value: "description" },
                { text: "Mill Id", value: "mill_id" },
                { text: "Mill Name", value: "mil_name" },
                { text: "Machine Type", value: "mach_type" },
                { text: "Division", value: "class" },
                { text: "Nama Mesin", value: "name_machine" },
                { text: "Req Date", value: "request_date" },
                { text: "Req By", value: "requested_by" },
                { text: "Category Maintenance", value: "category_description" },
                { text: "Req Sparepart", value: "require_sparepart" },
                { text: "Start Date", value: "start_date" },
                { text: "Finish Date", value: "finish_date" },
                { text: "Total Duration", value: "total_duration" },
                { text: "Total Lost Time", value: "total_lost_time" },
                { text: "Total Down Time", value: "total_down_time" },
                { text: "Total Item", value: "total_item" },
                { text: "Total Action", value: "total_action" },
                { text: "Total Inspect", value: "total_inspect" },
            ],
            unschedules: [],
            option_items: {},
            totalItem: 10,
            pagination: 1,
            dialog: false,
            text_dialog: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false,
            },
            mill_id: '',
            mill_ids: [],
            mach_id: '',
            mach_ids: [],
            mach_type: '',
            mach_types: [],
            requested_by: '',
            employees: [],
            request_date: '',
            dateProps: {
                headerColor: 'blue'
            },
            status: '',
            statuses: [
                {
                    'id' : 1,
                    'label' : 'Draft'
                },
                {
                    'id' : 2,
                    'label' : 'Maintenance'
                }
            ],
            description: '',
            btnSaveNew: '',
            dialog_save: false,
            detailMaintenance: {},
            display: 'none',
            maintenanceCategories: [],
            category: '',
            dialogEditMaintenance: false
        }
    },
    mounted(){
        this.getMill()
        this.getMasterMaintenanceCategory()
        this.getEnvConf()
        // this.getEmployee()
    },
    methods:{
        getEnvConf(){
            // console.log("# GET ENV CONF");
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {
                
                
                for (let i = 0; i < res.data.data.length; i++) {
                    
                    // if (res.data.data[i]['var_id'] === '60_UN_MTC') {
                    //     this.user_approve1 = res.data.data[i]['var_value']
                    //     console.log(res.data.data[i]['var_value'])
                    // }
                    
                    if (res.data.data[i]['var_id'] === '60_UN_MTC_ITEM') {
                        this.detailAccess = res.data.data[i]['var_value']
                        var create = this.detailAccess.substr(0,1);
                        var action = this.detailAccess.substr(1,1);
                        var inspect = this.detailAccess.substr(2,1);
                        var authorize = this.detailAccess.substr(3,1);
                        this.createDisabled = (create == "1")? false : true;
                        this.actionDisabled = (action == "1")? false : true;
                        this.inspectDisabled = (inspect == "1")? false : true;
                        this.authorizeDisabled = (authorize == "1")? false : true;
                        this.maintenanceDisabled = (action == "1" || inspect == "1")? false : true;
                    }
                }
            })
        },
        close(){
            this.dialog = false
            this.text_dialog = ''
            this.clear()
        },
        getEmployee(){
            var data = {
                'nik' : '',
                'category' : '',
                'nama': '',
                'is_active' : 1,
                'page' : 1,
                'item_per_page' : 100
            }

            this.$store
            .dispatch("getMasterMaintenanceEmployee", data)
            .then(res => {
                this.employees = res.data.data
            })
            .catch((err) => {

            })
        },
        async getMill(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.mills = res.data.data
                this.mill_ids = res.data.data
			})
			.catch(err => {
			})
        },
        async getDivision(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/master_division?mill_id=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                 //console.log(res.data);
                // console.log(res.data.errorCode);
                if(res.data.errorCode == "00"){
                    this.divisions = res.data.data.data
                } else {
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: res.data.errorMessage,
                        visible: true
                    };
                }
                
			})
			.catch(err => {
			})
        },
        async getMachineType(value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${this.mill ? this.mill : ''}&class=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machine_types = res.data
                this.mach_types = res.data
			})
			.catch(err => {
			})
        },
        async getMachineName(value){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${this.mill ? this.mill : ''}&mach_type=${value}`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.machines = res.data
                this.mach_ids = res.data
			})
			.catch(err => {
			})
        },
        createNew(){
            this.dialog = true
            this.text_dialog = 'Create'
            this.btnSaveNew = 'New'
            this.clear()
        },
        async search(page = 1, itemsPerPage = 10){
            this.loading = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?no_wr=${this.$store.state.text}&status=2&mill_id=${this.mill ? this.mill : ''}&class=${this.division ? this.division : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.unschedules = res.data.data
                this.totalItem = res.data.total
                this.pagination = res.data.current_page
            })
        },
        async editItem(item){
            this.display = 'none'
            // Ganti bagian ini, periksa statusnya
            console.log("EDIT ITEM");
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?no_wr=${item.no_wr}&status=2&mill_id=${this.mill ? this.mill : ''}&mach_type=${this.machine_type ? this.machine_type : ''}&mach_id=${this.machine ? this.machine : ''}&page=${'1'}&pagination=${'10'}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                // console.log(item);
                // console.log(res.data.data[0]);
                this.detailMaintenance = res.data.data[0]
                if(this.detailMaintenance == null) {
                    // console.log("null")
                    this.snackbar = {
                        color: "info",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Information",
                        text: 'WO sudah naik ke user',
                        visible: true
                    };
                } else {
                    // console.log("tidak null")
                    this.$store.dispatch('getDetailMaintainanceAction', { item })
                    console.log(this.$store.state.detailMaintenance);
                    this.dialog = true
                    this.text_dialog = 'Edit'
                }
            }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            // this.detailMaintenance = item
            
            this.clear()
        },
        async getMasterMaintenanceCategory() {
            var isActive = '1'
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/maintenance_category?is_active=${isActive}`,
                {
                    headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
                })
                .then((res) => {
                    // console.log(res.data);
                    this.maintenanceCategories = res.data;
                });
        },
        changeItemCategory(item){
            this.detailMaintenance = item
            this.dialogEditMaintenance = true
            this.category = item.category_id
        },
        async saveCategoryMaintenance(){
            await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_update_all`, {
                'no_wr' : this.detailMaintenance.no_wr,
                'mill_id': this.detailMaintenance.mill_id,
                'mach_type': this.detailMaintenance.mach_type,
                'mach_id': this.detailMaintenance.mach_id,
                'status': this.detailMaintenance.status,
                'description': this.detailMaintenance.description,
                'request_date': this.detailMaintenance.request_date,
                'category_id': this.category ? this.category : '',
            }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                this.dialogEditMaintenance = false
                this.category = ''
                this.search(1, 10)
            }).catch(err => {
                this.dialogEditMaintenance = false

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: err.response.data.message,
                    visible: true
                };
            })
        },
        clear(){
            this.mill_id = ''
            this.mach_type = ''
            this.mach_id = ''
            this.requested_by = ''
            this.request_date = ''
            this.status = ''
            this.description = ''
        },
        async save(){
            this.dialog_save = true

            if (this.mill_id === '' || this.mach_type === '' || this.mach_id === '' || this.requested_by === '' || this.request_date === '' || this.status === '' || this.description === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill data",
                    visible: true
                };

                this.dialog_save = false

                return false;
            }

            if (this.btnSaveNew === 'New') {
                await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance`, {
                    'mill_id': this.mill_id,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'description': this.description,
                    'requested_by': this.requested_by,
                    'request_date': this.request_date,
                    'status': this.status
                }, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                    //this.loading = false
                    this.dialog_save = false
                    this.dialog = false

                    this.search(1,10)
                    this.clear()
                }).catch(err => {
                    // this.loading = false
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        text: err.response.data.message,
                        visible: true
                    };
                })
            } else {
                
            }
        }
    },
    watch: {
        option_items: {
            handler () {
                const { page, itemsPerPage } = this.option_items
                this.search(page, itemsPerPage)
            },
            deep: true,
        }
    }
  }
</script>